export default function config() {
  const ACCESS_ABILITY_ATTACHMENTS = { action: 'change', subject: 'Attachments' }
  const tableColumns = [
    { key: 'actions', label: '' },
    { key: 'name', label: 'File Name' },
    { key: 'created_at', label: 'Date' },
    { key: 'attachment_type', label: 'Type' },
    { key: 'attachment_category', label: 'Category' },
  ]
  const attachmentTrashTableColumns = [
    { key: 'actions', label: '' },
    { key: 'name', label: 'File Name' },
    { key: 'updated_at', label: 'Deleted Date' },
    { key: 'attachment_type', label: 'Type' },
    { key: 'attachment_category', label: 'Category' },
  ]

  const dateOptions = {
    year: 'numeric',
    month: 'short',
    day: 'numeric',
    hour: 'numeric',
    minute: 'numeric',
  }

  const MODULE_NAME = 'attachments'

  return {
    tableColumns,
    dateOptions,
    attachmentTrashTableColumns,
    MODULE_NAME,
    ACCESS_ABILITY_ATTACHMENTS,
  }
}
