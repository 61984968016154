import crudActions from '@/libs/storeConstActions'
import axios from '@/libs/axios'
import store from '@/store'

const endpoint = 'attachments'
const currentCatalogId = () => store.state.catalogs?.catalog?.id
const Actions = crudActions(endpoint)

export default {
  ...Actions,
  'move-to-trash': (ctx, data) => axios.post(`/${endpoint}/${data.id}/move-to-trash`, data),
  'recover-from-trash': (ctx, data) => axios.post(`/${endpoint}/${data.id}/remove-from-trash`, data),
  getActiveAttachment: ({ commit }, queryParams) => new Promise((resolve, reject) => {
    axios.get(`products/${currentCatalogId()}/attachments`, { params: queryParams })
      .then(response => {
        commit('LIST', response.data.data.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
  getTrashedAttachment: ({ commit }, queryParams) => new Promise((resolve, reject) => {
    axios.get(`products/${currentCatalogId()}/attachments/trash`, { params: queryParams })
      .then(response => {
        commit('LIST', response.data.data.data)
        resolve(response)
      })
      .catch(error => {
        reject(error)
      })
  }),
}
