<template>
  <div>
    <router-view />
  </div>
</template>

<script>
import { onUnmounted } from '@vue/composition-api'
import store from '@/store'
// eslint-disable-next-line import/no-cycle
import attachmentModule from '@/store/main/catalog/attachment'
import config from './config'

export default {
  name: 'Attachments',
  created() {
    this.$emit('updateMenu', 'home-catalog-attachments')
  },
  setup() {
    const { MODULE_NAME } = config()
    if (!store.hasModule(MODULE_NAME)) store.registerModule(MODULE_NAME, attachmentModule)
    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(MODULE_NAME)) store.unregisterModule(MODULE_NAME)
    })
  },
}
</script>

<style scoped>

</style>
